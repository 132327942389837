// This file is generated via scripts/build-static-config.js
// Do not modify directly as changes will be overwritten in CI

export const CONFIGURATION = {
  country: 'us',
  brand: 'plk',
  env: 'prod',
  graphqlEnv: 'prod',
  graphqlGatewayEnv: '',
  sanityEnv: 'prod',
  platform: 'web',
  commitRef: 'a7c732d10ad0b14189a7ef69cbfa38194686c656',
  releaseTimestamp: '1690835699',
  sanityDataset: '',
  sanityProjectId: 'czqk28jt',
  appFlowBuildId: '',
  adyen: {
    clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
    environment: 'live-us',
    urlPrefix: 'https://checkoutshopper-live.adyen.com',
  },
  apiKeys: {
    amplitude: '99555209cdf5457b8030d16996120627',
    branch: 'key_live_jhUvqDvHVUO58ON1EuDaDcmoryj1M8j4',
    braze: 'a030e520-6a8c-4928-b7db-e1c5953000ff',
    bringg: 'ue4g_yYRGtPs-iZJw1JEHY1mH',
    googleMaps: 'AIzaSyBg3bbt86mBAGtZ3S67J3FEiDtDkJY0_As',
    googleAnalytics: '',
    tomtom: '',
    launchDarkly: '639c9e0630f1b011341f7009',
    launchDarklyMobileKey: 'mob-b7154f95-564a-4685-8415-0f406b435077',
    logRocket: 'mj7uvx/ctg-prod',
    mParticle: '1c4cf06d94a6204bae1d09ac8e14cbb4',
    radar: '',
  },
  apple: {
    merchantId: 'merchant.com.popeyes',
    migrationMerchantId: '',
    paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
    prepaidMerchantId: 'merchant.com.popeyes',
    migrationPrepaidMerchantId: '',
  },
  aws: {
    gqlApiUrl: 'https://use1-prod-plk.rbictg.com/graphql',
    gqlGatewayApiUrl: 'https://use1-prod-plk-gateway.rbictg.com/graphql',
    region: 'us-east-1',
    userPoolClientId: '6jl47h927uikv6n1386j99fuo6',
    userPoolId: 'us-east-1_qIsU20KIm',
  },
  bringg: {
    region: '6',
  },
  datadog: {
    logs: {
      clientToken: 'pub0048f82c565e9a608141557a1ea1fdef',
      dataCenter: '',
    },
    rum: {
      applicationId: '2ce34ad0-4de5-4ea7-91fe-9fda95fe4c73',
      clientToken: 'pub0048f82c565e9a608141557a1ea1fdef',
      dataCenter: '',
    },
  },
  forter: {
    siteId: '2d420121b401',
  },
  google: {
    paymentsEnvironment: 'prod',
    paymentsGateway: 'firstdata',
    paymentsGatewayMerchantId: {
      ca: '',
      us: '334970128883',
    },
    migrationPaymentsGateway: '',
    migrationPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    },
    paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
    prepaidPaymentsGateway: 'firstdata',
    prepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '334970128883',
    },
    migrationPrepaidPaymentsGateway: 'firstdata',
    migrationPrepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    }
  },
  googleAdManager: {
    campaignId: '',
  },
  googleTagManager: {
    containerId: 'GTM-TV9K36R',
  },
  oneTrust: {
    cookieCompliance: {
      consentCategoryBranch: 'C0004',
      consentCategoryBraze: 'C0002',
      consentCategoryMParticle: 'C0001',
      globalConsentState: 'Opt-Out',
      scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      scriptId: '1f315de3-68b3-4f4c-821c-1837a9da656d',
    },
    deleteAccountUrl: '',
  },
  pypestream: {
    scriptUrl: 'https://web.pypestream.com/api-launcher.js',
    appId: '00796be9-4b7b-4a50-a0f1-a84c457c6619',
  },
  restaurants: {
    displayEnv: 'prod',
    validMobileOrderingEnvs: ['alpha','beta','live'],
  },
  urls: {
    braze: 'https://customer.iad-03.braze.com/api/v3',
    firstData: 'https://api.payeezy.com',
    firstDataTh: '',
    webBaseUrl: '',
    thDigital: '',
  },
  tokenEx: {
    publicKey: '',
  },
  vrPayment: {
    braintreeTokenizationKey: '',
  },
  orbital: {
    baseUrl: '',
    subscriberId: '',
    pieFormat: '',
    mode: '',
  },
  timeFormat: 'hh:mm aaaa',
  dateFormat: '',
  appStoreDeeplink: 'https://unzb4-web.app.link/e/forceupdate',
};